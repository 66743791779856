import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import i18n from '@/libs/i18n'

export default function useCompaniesList() {
  // Use toast
  const toast = useToast()

  const refCompanyListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: i18n.t('companies.company'),
      formatter: title,
      sortable: true,
    },
    { key: 'code', label: i18n.t('companies.code'), sortable: true },
    { key: 'email', label: i18n.t('companies.email'), sortable: true },
    { key: 'contact', label: i18n.t('companies.contact'), sortable: true },
    { key: 'actions', label: i18n.t('common.actions') },
  ]
  const perPage = ref(10)
  const totalCompanies = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCompanyListTable.value ? refCompanyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCompanies.value,
    }
  })

  const refetchData = () => {
    refCompanyListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchCompanies = (ctx, callback) => {
    // console.log('!!!!!!!!!!!!!')
    store
      .dispatch('app-company/fetchCompanies', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        // console.log('!!!!!!!!!!!!!')
        // console.log(response)
        const { companies, total } = response.data

        callback(companies)
        totalCompanies.value = total
      })
      // .catch(err => {
      .catch(() => {
        // console.log('!!!!!!!!!!!!!')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching companies list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        // console.log(err)
      })
  }

  const deleteCompany = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Company <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-company/deleteCompany', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Company ${item.fullName} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Company delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCompanyRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveCompanyRoleIcon = role => {
    if (role === 'subscriber') return 'CompanyIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'CompanyIcon'
  }

  const resolveCompanyStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchCompanies,
    tableColumns,
    perPage,
    currentPage,
    totalCompanies,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCompanyListTable,

    resolveCompanyRoleVariant,
    resolveCompanyRoleIcon,
    resolveCompanyStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,

    deleteCompany,
  }
}
