<template>
  <div>

    <!-- Media -->
    <!--b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="companyData.avatar"
          :text="avatarText(companyData.fullName)"
          :variant="`light-${resolveCompanyRoleVariant(companyData.role)}`"
          size="90px"
          rounded
        />
      </template-->
    <h4 class="mb-1">
      {{ companyData.name }}
    </h4>
    <!--div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media-->

    <!-- Company Info: Input Fields -->
    <b-form
      @submit_.prevent="handleSubmit(onSubmit)"
    >
      <b-row>

        <!-- Field: Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('companies.company_name')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="companyData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('companies.code')"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="companyData.code"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('companies.email')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="companyData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            :label="$t('companies.address')"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="companyData.address"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -- >
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card-->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      type_="submit"
      @click="onSubmit"
    >
      {{ $t('common.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('common.reset') }}
    </b-button>
  </div>
</template>

<script>
import {
  // BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useCompaniesList from '../companies-list/useCompaniesList'

export default {
  components: {
    BButton,
    // BMedia,
    // BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    // vSelect,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveCompanyRoleVariant } = useCompaniesList()

    /*
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Company',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]
    */

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.companyData.avatar = base64
    })

    const ok = {
      code: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      // console.log(props.userData)
      resOk.value = ok
      store.dispatch('app-company/addCompany', props.companyData)
        .then(() => {
          router.push({ name: 'apps-companies-list' })
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    return {
      resolveCompanyRoleVariant,
      avatarText,
      // roleOptions,
      // statusOptions,
      // permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      onSubmit,
      resOk,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
