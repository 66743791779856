<template>
  <component :is="companyData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="companyData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching company data
      </h4>
      <div class="alert-body">
        No company found with this company id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-companies-list'}"
        >
          Company List
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <b-tabs
      v-if="companyData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="CompanyIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('companies.company') }}</span>
        </template>
        <company-edit-tab-account
          :company-data="companyData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -- >
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <company-edit-tab-information class="mt-2 pt-75" />
      </b-tab-->

      <!-- Tab: Social -- >
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <company-edit-tab-social class="mt-2 pt-75" />
      </b-tab-->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import companyStoreModule from '../companyStoreModule'
import CompanyEditTabAccount from './CompanyEditTabAccount.vue'
// import CompanyEditTabInformation from './CompanyEditTabInformation.vue'
// import CompanyEditTabSocial from './CompanyEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CompanyEditTabAccount,
    // CompanyEditTabInformation,
    // CompanyEditTabSocial,
  },
  setup() {
    const companyData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-company'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-company/fetchCompany', { id: router.currentRoute.params.id })
      .then(response => { companyData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          companyData.value = undefined
        }
      })

    return {
      companyData,
    }
  },
}
</script>

<style>

</style>
